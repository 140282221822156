import EnsureLogin from '@/components/EnsureLogin'
import DashboardLayout from '@/layout/dashboard/DashboardLayout'
import dynamic from 'next/dynamic'

const Dashboard = dynamic(() => import('@/features/dashboard/Dashboard'))

export default function Index() {
  return (
    <EnsureLogin>
      <DashboardLayout title="대시보드">
        <Dashboard />
      </DashboardLayout>
    </EnsureLogin>
  )
}
